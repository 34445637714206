<template>
  <div class="bg">
    <div class="header">
      <div class="headerL">
        <router-link to="/">
          <img src="@/assets/image/logo.png" alt="logo" />
        </router-link>
      </div>
      <div class="title">第三方信息共享清单</div>
    </div>
    <div class="main">
      <div>
        说明：<br />
        1、为保障本应用的相关功能的实现与应用安全稳定的运行，我们可能会接入由合作方提供的软件开发包（SDK）、应用程序接口（API）、应用程序插件等代码或通过其他合作方式，以实现相关目的。<br />
        2.我们对接入的相关合作方在目录中列明，合作方具体的处理情况请参见合作方的隐私政策。<br />
        3.请注意，合作方的代码可能因为其版本升级、策略调整等原因导致数据处理类型存在一定变化，请以其公示的官方说明为准。
      </div>
      <br />
      <div>
        SDK名称：微信支付SDK<br />
        使用目的：帮助用户在网站内使用微信支付<br />
        共享信息：支付订单标识、支付时间、支付金额、设备标识信息（IMEI、IMSI、AndroidID、MAC、SIM卡、DeviceID）、硬件序列号、网络信息<br />
        共享方式：SDK本机采集<br />
        第三方主体：深圳市腾讯计算机系统有限公司<br />
        官网链接：https://www.pingxx.com/terms.html#privacy
      </div>
      <br />
      <div>
        SDK名称：支付宝支付SDK<br />
        使用目的：帮助用户在网站内使用支付宝支付<br />
        共享信息：设备标识信息（IMEI、IMSI、AndroidID、MAC地址、SIM卡序列号）、网络信息<br />
        共享方式：SDK本机采集<br />
        第三方主体：支付宝（中国）网络技术有限公司<br />
        官网链接：https://opendocs.alipay.com/open/54/01g6qm
      </div>
      <br />
    </div>
  </div>
</template>
<style scoped>
p {
  text-indent: 2rem;
}
.header {
  background-color: #47475c;
  color: #ffffff;
  overflow: hidden;
}
.headerL {
  float: left;
  color: #ffffff;
  overflow: hidden;
}
.headerL img {
  height: 3.2rem;
  padding: 0.3rem;
  margin-left: 0.5rem;
}
.title {
  font-size: 2rem;
  text-align: center;
  line-height: 60px;
  width: 800px;
  margin: 0 auto;
}
.bg {
  background-color: #eee;
}
.main {
  padding: 1.786em 2.5em;
  background-color: #fff;
  max-width: 1000px;
  margin: 2rem auto;
  line-height: 2.2em;
  margin-bottom: 0;
}
.h1 {
  font-weight: 500;
  font-size: 1.5rem;
  padding: 1.5rem 0;
}
.weight {
  font-weight: 600;
}
ul {
  list-style-type: disc;
  padding-left: 3rem;
}
.pl1 {
  padding-left: 1rem;
}
.underL {
  text-decoration: underline;
}
</style>
